jQuery.noConflict();
  (function( $ ) {
    $(function() {
        var timer = 150;
        var grid = $('.flex-images');
        if(grid.length){
          grid.flexImages({
              rowHeight: 250
          });
        }
          
        $(document).on("click",'.item .close' , function (e) {
            close();
        });

        $(document).on("click",'.content-image .expand-icon' , function (e) {
            e.preventDefault();
            var _this = $(this).parents('.content-image');

            $('video').each(function() {
              $(this).get(0).pause();
            });
            
            animateExpander(_this);
        });


        // Used to update grid image sizes on window resize
        $(window).resize(function(){
            setTimeout(function(){
              var imageHeight = $('.flex-images .item.active .content-image').innerHeight();
              var contentHeight = $('.flex-images .item.active').find('.expander .wrapper .inner .row').innerHeight();
              $('.flex-images .item.active').css({
                height: imageHeight + contentHeight + 5 + "px"
              });
              $('.flex-images .item.active .expander').animate({
                height: contentHeight
              }, timer);
            }, 50);
  
            setTimeout(function(){
              layout();
            }, 250);
        });

        if ($('audio, video').length) {
          $('audio, video').each(function() {
            var playerId = $(this).attr('id');
            var myPlayer = videojs(playerId, {
              "fluid": true
            });
          });
        }

        function close() {
            $item = $(".item.active");
            $item.addClass("inactive").removeClass("active");
            if ($item.attr('data-height')) {
              $item.animate({
                height: $item.attr('data-height')
              }, timer);
              $item.find(".expander").animate({
                height: 0
              }, timer);
            }
            setTimeout(function(){
              $(window).trigger('resize');
            }, 250)
        };
        
        function animateExpander(_this){
            $("[data-toggle='tooltip']").tooltip('hide');
            var gridWrapper = _this.parent().parent().parent();
            var mediaId = _this.attr('data-id');
            var mediaUrl = _this.attr('data-media-url');
            var mediaType = _this.attr('data-type');
            var $parent = _this.parent();
            var currentItem = gridWrapper.find('.item.active');
            currentItem.find('.expander .preloader').show();
            currentItem.find('.expander .media-wrapper').html("");
            console.log('animateExpander');
            console.log(mediaUrl);
            $.ajax({
                async: true,
                type : "GET",
                dataType: "html",
                url : amoAjax.ajaxurl,
                data : {
                  action : "search_get_expander_media",
                  nonce : $('meta[name="amo-csrf"]').attr('content'),
                  mediaId : mediaId,
                  mediaUrl : mediaUrl,
                  mediaType : mediaType
              },
            }).done(function(data){
              currentItem.find('.expander .preloader').hide(); 
              currentItem.find('.expander .media-wrapper').html(data);
            });
        
            if($parent.hasClass('active')) return false;
        
            var imageHeight = _this.innerHeight();
        
            if (!$parent.attr('data-height')) {
                $parent.attr({'data-height': $parent.css("height")});
            }
            $parent.height('auto');
            gridWrapper.find('.item.active').addClass("inactive").removeClass("active");
            $parent.addClass("active").removeClass("inactive");
        
            var contentHeight = 450;
            gridWrapper.find('.item.active').animate({
                height: imageHeight + contentHeight + 150
            }, timer);
            gridWrapper.find('.item.active .expander').animate({
                height: contentHeight + 150
            }, timer);
        
            var currentItem = gridWrapper.find('.item.active');
            setTimeout(function(){
                $(currentItem).get(0).scrollIntoView();
                gridWrapper.find('.item.inactive').each(function() {
                  $(this).css('height', 0);
                  $(this).find(".expander").css('height', 0);                 
                });
            }, timer);
        }
        
        function layout() {
            var height = $(".item.inactive:first").css("height");
            $(".item .content-image").each(function(){
                if(!$(this).parent().hasClass("active")) {
                    $(this).height($(this).parent().css("height"));
                } else {
                    var hasPrevItem = $(this).parent().prev();
                    var hasNextItem = $(this).parent().next();
        
                    if (hasPrevItem.length && hasPrevItem.offset().top === $(this).offset().top){
                    $(this).height(hasPrevItem.css("height"));
                    } else if (hasNextItem.length && hasNextItem.offset().top === $(this).offset().top) {
                    $(this).height(hasNextItem.css("height"));
                    }
                }
            });
        };

        $.extend({
          getUrlVars: function(){
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++)
            {
              hash = hashes[i].split('=');
              vars.push(hash[0]);
              vars[hash[0]] = hash[1];
            }
            return vars;
          },
          getUrlVar: function(name){
            return $.getUrlVars()[name];
          }
      });
  
      var urlParams = $.getUrlVars();

      $(document.body).on('change', '#range-select', function(e){
          var perPage = $(this).val();
          updateUrlSearchParams(urlParams.searchtype, urlParams.keyword, perPage, urlParams.range, '1', urlParams.ordering, urlParams.mediatype);
      });


      $(document.body).on('change', '#page-select', function(e){
        var pagenum = $(this).val();
        updateUrlSearchParams(urlParams.searchtype, urlParams.keyword, urlParams.per_page, urlParams.range, pagenum, urlParams.ordering, urlParams.mediatype);
      });

      


      $(document.body).on('change', '#order-select', function(e){
          var ordering = $(this).val();
          updateUrlSearchParams(urlParams.searchtype, urlParams.keyword, urlParams.per_page, urlParams.range, urlParams.pagenum, ordering, urlParams.mediatype);
      });


      function updateUrlSearchParams(searchType, keyword, perPage, range, pageNum, ordering, mediaType)
      {   
          var range = calculateRange(pageNum, perPage);
          var url = $.query
              .set("per_page", perPage)
              .set("range", range)
              .set("pagenum", pageNum)
              .set("ordering", ordering)
              .toString();
          window.location.href = url;
      }

    

      function calculateRange(pageNum, perPage)
      {
          if (pageNum === null || pageNum === undefined || pageNum === '') {
              pageNum = 1;
          }
          if (perPage === null || perPage === undefined || perPage === '') {
              perPage = 50;
          }

          var rangeEnd = parseFloat(perPage) * parseFloat(pageNum);
          var rangeStart = (parseFloat(rangeEnd) - parseFloat(perPage)) + 1;
          var range = rangeStart+'-'+rangeEnd;
          return range;
      }


    });
})(jQuery);

